import React, { useContext, useState } from 'react'
import Data from "../Data.json"
import CrossIcon from "../../Assets/Images/Cancel.svg"
import { useNavigate } from 'react-router-dom'
import { RxCross2 } from "react-icons/rx";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { NumericInput, AlphaNumericInput, AlphaInput } from "../Switch/Validation"
import { context } from '../Context/Context';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const AddAgent = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const { formData, setFormData } = useContext(context);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("")



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  const fieldSet = (Data.addAgent)
  const navigate = useNavigate()
  // const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  const handleSubmit = () => {
    console.log("Data added successfully");
    setSnackbarMessage("Data added successfully");
    setSnackbarOpen(true)
    setFormData({})
  }

  return (
    <div className='container-fluid text-color-dark'>
      <div className="row">
        <div className="col-1"></div>
        <div className="col text-center"><h5>Add Agent</h5></div>
        <div className="col-1 text-end">
          <RxCross2 fontSize={22} onClick={() => navigate("/", { state: { activeName: "Dashboard" } })} />
        </div>
      </div>

      {Object.keys(fieldSet).map((dataSet, index) => (
        <>
          <div className="d-flex align-items-center my-2">
            <div className="me-3" style={{ whiteSpace: "nowrap" }}><label className='fw-bold text-muted'>{dataSet}</label></div>
            <div className='w-100'><hr /></div>
          </div>
          <div className="row align-items-center px-5">
            {fieldSet[dataSet].map((fields) => (
              <>
                <div className="col-lg-3 col-sm-6 my-2"><label>{fields.label}</label></div>
                <div className="col-lg-3 col-sm-6 my-2">
                  {
                    fields.type === "dropdown" ? <>
                      <select class="form-select w-100" name={fields.name} onChange={handleChange}>
                        <option value="" selected={false}>Select {fields.label}</option>
                        {fields.dropdownOption.map((option) => (
                          <option value={option}>{option}</option>
                        ))}

                      </select></>
                      :
                      fields.type === "date" ? <>
                        <>
                          {console.log(formData[fields.name] === undefined ? "red" : "grey")
                          }
                          <input type={"date"}
                            className={`w-100 form-control ${formData[fields.name]!==undefined&&formData[fields.name]!==""?"date-input--has-value":""}`}
                            name={fields.name}
                            style={{ color: formData[fields.name] === undefined ? "red" : "grey" }}
                            value={formData[fields.name] || ''}
                            onChange={handleChange}
                            placeholder={`Enter ${fields.label}`} /></>

                      </> :
                        fields.type === "password" ? <>
                          <div className="position-relative">
                            <input
                              type={passwordShow ? "text" : "password"}
                              name={fields.name}
                              value={formData[fields.name] || ""}
                              onChange={handleChange}
                              placeholder={`Enter ${fields.label}`}
                              className="form-control w-100"
                            />
                            {
                              passwordShow ?
                                <FaRegEyeSlash
                                  style={{ cursor: 'pointer' }}
                                  fontSize={25}
                                  onClick={() => setPasswordShow(false)}
                                  className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                /> :
                                <FaRegEye
                                  style={{ cursor: 'pointer' }}
                                  fontSize={25}
                                  onClick={() => setPasswordShow(true)}
                                  className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                                />
                            }
                          </div>
                        </> :
                          <>
                            <input
                              onKeyPress={fields?.validation[0] === "Alpha" ? AlphaInput : fields?.validation[0] === "Numeric" ? NumericInput : AlphaNumericInput}
                              maxLength={fields?.validation[1] === "" ? 100 : parseInt(fields?.validation[1])}
                              className='w-100 form-control'
                              name={fields.name}
                              value={formData[fields.name] || ''}
                              onChange={handleChange}
                              placeholder={`Enter ${fields.label}`} /></>
                  }



                </div>

              </>
            ))}
          </div>
        </>
      ))}
      <div className="text-center my-5">
        <button className='button px-5 py-2 ' onClick={handleSubmit}>Save</button>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className='snackBar'
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
    </div>
  )
}

export default AddAgent
