import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CrossIcon from "../../Assets/Images/Cancel.svg"
import { useNavigate } from 'react-router-dom';
import Data from "../Data.json"
import { FaArrowDownLong } from "react-icons/fa6";
import { IoAdd } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import { Drawer } from '@mui/material';
import { RxCross2 } from "react-icons/rx";
import { FaChevronLeft } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";

const columnsData = (Data)

const FilterSection = ({ setOpenFilter }) => {
  return (
    <>
      <div className="text-center my-1"><h5>Filter</h5></div>
      <div className="position-absolute bottom-0 my-1 w-100">
        <div className="d-flex align-bottom justify-content-center w-100 align-item-center">
          <div className="btn themeButton px-4 mx-1">Apply</div>
          <div className="btn whiteThemeButton px-4 mx-1" onClick={() => { setOpenFilter(false) }}>Close</div>
        </div>
      </div>
    </>
  )
}
const CustomReportWizard = ({ tabs, setColumns, selectedCategory, setSelectedCategory, setFieldset, fieldset, selectedFieldset, setSelectedFieldset, setSelectedTab }) => {
  const [show, setShow] = useState("selectCategory")

  const handleNext = () => {
    let d = []
    if (selectedCategory.name === "vehicleinsurance") {
      d = columnsData.carAndBikeInsurance
    }
    else if (selectedCategory.name === "healthinsurance") {
      d = columnsData.healthInsurance
    }
    else if (selectedCategory.name === "lifeinsurance") {
      d = columnsData.lifeInsurance
    }
    else if (selectedCategory.name === "loan") {
      d = columnsData.loanAndCreditCard
    }
    const rec = []
    Object.values(d).map((a, i) => (
      a.map((val) => (
        rec.push(val)
      ))
    ))
    setFieldset(rec)

    setShow("setFieldset")

  }

  const handleSelectCategory = (categoryName) => {
    if (selectedCategory === categoryName) {
      setSelectedCategory(null)
    }
    else {
      setSelectedCategory(categoryName)
    }
  }

  const handleSelectFieldSet = (obj) => {
    let arr = [...selectedFieldset]

    const index = arr.findIndex(item => JSON.stringify(item) === JSON.stringify(obj));

    if (index === -1) {
      // If object is not found, push it into the array
      arr.push(obj);
    } else {
      // If object is found, remove it from the array
      arr.splice(index, 1);
    }
    setSelectedFieldset(arr)
  }

  const goback = (() => {
    if (show === "setFieldset") {
      setShow("selectCategory")
    }
  })
  const handleFinish = (() => {
    setSelectedTab({ name: selectedCategory.name, label: "Custom Report Table" })
    setColumns(selectedFieldset)
  })

  return (
    <>  <div className="row my-3">
      <div className="col-4">

        <FaChevronLeft onClick={goback} />
      </div>
      <div className="col-4 text-center">
        <label className='fw-bold'>Custom Report Wizard</label>

      </div>
      <div className="col-4 text-end">

        {
          show === "selectCategory" ?
            <button className='button px-4 py-2' onClick={handleNext}>Next <FaArrowRightLong fontSize={20} className="mx-1" /></button> :
            <button className='button px-4 py-2' onClick={handleFinish}>Finish  <FaArrowRightLong fontSize={20} className="mx-1" /></button>
        }
      </div>

    </div>
      {
        show === "selectCategory" ?
          <>
            <div className="text-center text-muted my-3">Select Category</div>
            <div className='position-absolute start-50 translate-middle-x'>
              {

                tabs.map((tab, index) => (
                  <>{
                    tab.label !== "Custom Report" ? <>
                      <div className='d-flex align-items-center my-2'>
                        <div className={`${tab.name === selectedCategory?.name && tab.label === selectedCategory?.label ? "selected-checkBox" : "checkBox"} mx-2`} onClick={() => handleSelectCategory(tab)} />
                        <label className='mx-2'>{tab.label}</label>
                      </div>
                    </ > : null
                  }
                  </>
                ))
              }
            </div>


          </> : <>
            <div className="text-center text-muted my-2">Select Field Set</div>
            <div className="row">
              {fieldset.map((column, index) => (
                <div className="col-3">
                  <div className='d-flex align-items-center my-2'>
                    <div className={`${selectedFieldset.some(item => JSON.stringify(item) === JSON.stringify(column)) ? "selected-checkBox" : "checkBox"} mx-2 flex-shrink-0`} onClick={() => handleSelectFieldSet(column)} />
                    <label className='mx-2'>{column.label}</label>
                  </div>
                </div>
              ))}
            </div>
          </>

      }
    </>
  )
}



export default function DataView() {


  const [selectedCategory, setSelectedCategory] = useState(null)
  const [fieldset, setFieldset] = useState([])
  const [selectedFieldset, setSelectedFieldset] = useState([])
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const tabs = [{ name: "customReport", label: "Custom Report" },
  { name: "vehicleinsurance", label: "Car Insurance" },
  { name: "vehicleinsurance", label: "Bike Insurance" },
  { name: "healthinsurance", label: "Health Insurance" },
  { name: "lifeinsurance", label: "Life Insurance" },
  { name: "loan", label: "Loan" },
  ]
  const [selectedTab, setSelectedTab] = React.useState({ name: "vehicleinsurance", label: "Car Insurance" })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // console.log(selectedTab) 
  const [records, setRecords] = React.useState([])
  const [displayRecords, setDisplayRecords] = React.useState([])
  const [columns, setColumns] = React.useState([])
  const [openFilter, setOpenFilter] = React.useState(false)




  React.useEffect(() => {
    let d = []
    if (selectedTab.label !== "Custom Report Table") {
      setSelectedCategory(null)
      setSelectedFieldset([])
      if (selectedTab.name === "vehicleinsurance") {
        d = columnsData.carAndBikeInsurance
      }
      else if (selectedTab.name === "healthinsurance") {
        d = columnsData.healthInsurance
      }
      else if (selectedTab.name === "lifeinsurance") {
        d = columnsData.lifeInsurance
      }
      else if (selectedTab.name === "loan") {
        d = columnsData.loanAndCreditCard
      }
      const rec = []
      Object.values(d).map((a, i) => (
        a.map((val) => (
          rec.push(val)
        ))
      ))
      setColumns(rec)
    }



    const fetchData = async () => {
      try {
        // console.log(selectedTab)
        const response = await fetch(`http://localhost:8000/dataView?tblname=${selectedTab.name}`);

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        if (result.msg !== "No data found") {


          // setproducts(result.element);
          setRecords(result.element)
          setDisplayRecords(result.element)
          console.log(result, "kkjfnn");
        }


      } catch (err) {
        console.log(err);

      }
    };
    fetchData();


  }, [selectedTab])

  return (
    <div className="container-fluid text-color-dark">
      <div className="d-flex justify-content-between mb-3">
        <label className='fw-bold'>Data View</label>
        <RxCross2 fontSize={22} onClick={() => navigate("/", { state: { activeName: "Dashboard" } })} />
      </div>

      <div className="d-flex justify-content-start mt-3 mx-2">
        {tabs.map((tab) => (
          <button
            className={`mx-1 px-3 py-1  ${selectedTab.label === tab.label ? "seletedTabButtons  " : "tabButtons"}`}
            onClick={() => setSelectedTab(tab)}>
            {tab.label === "Custom Report" ? <IoAdd />
              : ""}

            {tab.label}</button>
        ))}
      </div>
      <div className={`tableOuter px-3 shadow-sm position-relative`} style={{ overflowX: "hidden" }}>
        {
          selectedTab.label === "Custom Report" ? <>
            {/* <div className="text-center">Custom Report Wizard</div> */}
            <CustomReportWizard tabs={tabs} setColumns={setColumns} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} setFieldset={setFieldset} fieldset={fieldset} selectedFieldset={selectedFieldset} setSelectedFieldset={setSelectedFieldset} setSelectedTab={setSelectedTab} />

          </>
            :
            <>{

              <div className={`filterSection ${openFilter === true ? "openFilter" : ""}`}><FilterSection setOpenFilter={setOpenFilter} /></div>
            }

              <div className="text-end mt-3 mb-2">
                <FiFilter onClick={() => { setOpenFilter(true) }} />
              </div>
              <TableContainer sx={{ maxHeight: "51vh", borderRadius: "0.5cm " }}>
                <Table stickyHeader aria-label="sticky table" sx={{ zIndex: 999 }}>
                  <TableHead >
                    <TableRow >
                      {columns.map((column) => (
                        <TableCell
                          className="table-head-cell fw-bold"
                          key={column.name}
                          align="center"
                          style={{ minWidth: 170, whiteSpace: "nowrap" }}
                        >
                          {/* <div className="d-flex align-items-center border"> */}

                          {column.label} <FaArrowDownLong style={{ cursor: "pointer" }} />
                          {/* </div> */}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? displayRecords?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : displayRecords
                    )?.map((items, index) => (
                      <TableRow>
                        <>{
                          columns.map((label, index) => (
                            <TableCell align='center' className='table-body-cell'>{items[label.name] === null ? "- - -" : items[label.name]}</TableCell>
                          ))
                        }
                        </>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-pagination'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={displayRecords?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
        }
      </div>
    </div>
  );
}

