import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Data from "../Data.json"
import { FaArrowDownLong } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { TextField } from '@mui/material';

const ViewAgent = () => {
    const [columns,setColumns]=useState([])
    const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayRecords,setDisplayRecords]=useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    useEffect(() => {
        let d =Data.addAgent       
        const rec = []
        Object.values(d).map((a, i) => (
          a.map((val) => (
            rec.push(val)
          ))
        ))
        setColumns(rec)    
      }, [])
  return (
    <div className='fluid-container text-color-dark'>
        <div className={`tableOuter px-3 shadow-sm position-relative`} style={{overflowX:"hidden"}}>   
            <div className="my-3">
                <label htmlFor="employeeList" className="fs-5">Employee List</label>
                </div>  
                <div className="my-3 px-2">
                <TextField id="outlined-basic"  label="Search" variant="outlined" className='w-100 materialuiInput' />
                    </div>  
              <TableContainer sx={{ maxHeight: "51vh", borderRadius: "0.5cm " }}>
                <Table stickyHeader aria-label="sticky table" sx={{ zIndex: 999 }}>
                  <TableHead >
                    <TableRow >
                      {columns.map((column) => (
                        <TableCell
                          className="table-head-cell fw-bold"
                          key={column.name}
                          align="center"
                          style={{ minWidth: 170, whiteSpace: "nowrap" }}
                        >
                          {/* <div className="d-flex align-items-center border"> */}

                          {column.label} 
                          {/* <FaArrowDownLong style={{ cursor: "pointer" }} /> */}
                          {/* </div> */}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{border:"2px solid red"}}>
                        {/* {(rowsPerPage > 0
                        ? displayRecords?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : displayRecords
                        )?.map((items, index) => (
                        <TableRow>
                            <>{
                            columns.map((label, index) => (
                                <TableCell align='center' className='table-body-cell'>{items[label.name]}</TableCell>
                            ))
                            }
                            </>

                        </TableRow>
                        ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-pagination'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={displayRecords?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
        
    
      </div>
      
    </div>
  )
}

export default ViewAgent
