import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import BlackAddAgent from "../../Assets/Images/BlackAddAgent.svg"
import WhiteAddAgent from "../../Assets/Images/WhiteAddAgent.svg"
import BlackBikeInsurance from "../../Assets/Images/BlackBikeInsurance.png"
import WhiteBikeInsurance from "../../Assets/Images/WhiteBikeInsurance.png"
import WhiteCarInsurance from "../../Assets/Images/WhiteCarInsurance.svg"
import BlackCarInsurance from "../../Assets/Images/BlackCarInsurance.svg"
import BlackInsurance from "../../Assets/Images/BlackInsurance.svg"
import WhiteInsurance from "../../Assets/Images/WhiteInsurance.svg"
import WhiteManageTeam from "../../Assets/Images/WhiteManageTeam.svg"
import BlackManageTeam from "../../Assets/Images/BlackManageTeam.svg"


import { LuCrown } from "react-icons/lu";
import { FaArrowRightLong } from "react-icons/fa6";
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { PiHandHeart } from "react-icons/pi";
import { PiHandCoins } from "react-icons/pi";
import { RiFundsLine } from "react-icons/ri";
import { TbPhoneCall } from "react-icons/tb";
import { LuUsers2 } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";

import { context } from '../Context/Context';
import { Box, Modal } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
};

const Sidebar = ({ active, handleClick, setActive }) => {
  const { formData, setFormData } = useContext(context);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate()
  const isDarkMode = localStorage.getItem("isDarkMode")==="false"?false:localStorage.getItem("isDarkMode")
  const [showSubmenu, setShowSubmenu] = useState(null);

  const toggleSubmenu = (submenuName) => {

    setShowSubmenu(showSubmenu === submenuName ? null : submenuName)
  };
  const location = useLocation()
  useEffect(() => {
    if (location?.state?.activeName === "Dashboard") {
      setShowSubmenu(null)
      setActive(location.state.activeName)
    }
  })

  const menuItems = [
    { name: "Dashboard", path: "/", icon: <RiDashboardHorizontalLine /> },
    {
      name: "Insurance", hasSubmenu: true, icon: <PiHandHeart />,
      submenu: [
        { name: "Car Insurance", path: "carInsurance", data: "carInsurance", whiteIcon: WhiteCarInsurance, blackIcon: BlackCarInsurance },
        { name: "Bike Insurance", path: "bikeInsurance", data: "bikeInsurance", whiteIcon: WhiteBikeInsurance, blackIcon: BlackBikeInsurance },
        { name: "Life Insurance", path: "lifeInsurance", data: "lifeInsurance", whiteIcon: WhiteInsurance, blackIcon: BlackInsurance },
        { name: "Health Insurance", path: "healthInsurance", data: "healthInsurance", whiteIcon: WhiteInsurance, blackIcon: BlackInsurance },
      ],
    },
    { name: "Loans", path: "loans", icon: <PiHandCoins /> },
    {
      name: "Mutual Funds", path: "", icon: <RiFundsLine />
    },
    { name: "Calling Management", path: "", icon: <TbPhoneCall /> },
    {
      name: "Team Management", hasSubmenu: true, icon: <LuUsers2 />,
      submenu: [
        { name: "Add Agent", path: "addAgent", whiteIcon: WhiteAddAgent, blackIcon: BlackAddAgent },
        { name: "Manage Agent", path: "viewAgent", whiteIcon: WhiteManageTeam, blackIcon: BlackManageTeam },

      ],
    },

    {
      name: "Reports", path: "reportsDataView", icon: <TbReportAnalytics />
    },
  ];
  const[path,setPath]=useState([])
  
    console.log(formData)


    return (
      <>
        <div className="sidebar border-end">
          <div className="sidebar-top d-flex align-items-center justify-conteny-center p-2" onClick={() => navigate("#")}>


            <img
              src={""}
              alt="logo"
              className="mx-3"
              style={{ width: "40px", height: "40px" }}
            />
            <label
              className="fw-bold"
              style={{ fontSize: "1.5rem" }}
            > Insurance Portal</label>


          </div>

          <div
            className={`d-flex flex-column p-2 justify-content-between sidebar-bottom  pt-3`}
          >
            <div className="pb-1">
              <ul className="nav nav-pills flex-column mb-auto ">
                {menuItems.map((item, index) => (
                  <li key={index} className={`nav-item `}>
                    <Link
                      style={{ fontWeight: "500", fontSize: "1.1rem" }}
                      to={Object.keys(formData).length<1?item.path:"#"}
                      className={`nav-link ${active === item.name ? "active" : "text-color-dark"} d-flex align-items-center `}
                      onClick={() => {
                        toggleSubmenu(item.hasSubmenu && Object.keys(formData).length<1? item.name : null)
                        handleClick(Object.keys(formData).length<1?item.name:active)
                        setOpen(Object.keys(formData).length<1?false:true)
                        setPath([item.path,item.name,item.hasSubmenu])
                      }}
                    >


                      <div className="d-block align-items-center"><label className="mx-1 fs-4" style={{ cursor: "pointer" }}>{item.icon}</label><label className="mx-1 " style={{ cursor: "pointer" }}>{item.name}</label></div>
                    </Link>
                    {item.hasSubmenu && showSubmenu === item.name && (

                      <ul className="nav flex-column ms-4 tree-list " >
                        {item.submenu.map((subItem, subIndex) => (
                          <li key={subIndex} className="nav-item">
                            <Link
                              to={Object.keys(formData).length<1?subItem.path:"#"}
                              state={subItem.data}
                              className={`nav-link ${active === subItem.name ? "text-light active" : "text-color-dark"} mx-2`}
                              onClick={() =>{ handleClick(Object.keys(formData).length<1?subItem.name:active)
                                setOpen(Object.keys(formData).length<1?false:true)
                                setPath([subItem.path,subItem.name])}}
                              style={{ fontSize: "1rem" }}
                            >
                              {/* {console.log(active === subItem.name || isDarkMode)} */}
                              <img src={(active === subItem.name || isDarkMode) ? subItem.whiteIcon : subItem.blackIcon} alt={subItem.name} width={20} className="mx-2 fw-bold" />
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>

                    )}
                  </li>
                ))}
              </ul>
            </div>



            <div className={`themeButton border mx-2 position-relative ${showSubmenu ? "mt-5" : ""}`} style={{ cursor: "pointer" }}>
              <div className="position-absolute top-0 rounded-5 start-50  ms-3" style={{ width: "35px", height: "40px", background: "white" }}></div>
              <div className="position-absolute top-50 rounded-5 start-0 ms-5 mt-3" style={{ width: "55px", height: "40px", background: "white" }}></div>
              <div className="glassMorphism px-3 pt-3 pb-1">
                <span className="white-background padding-both ">
                  <LuCrown fontSize={30} style={{ cursor: "pointer" }} className="my-2 " />
                </span>
                <p className="mt-1 mb-1 fs-4 ">Upgrade Pro</p>
                <div style={{ cursor: "pointer" }} className="my-0">Discover the benefits of an Upgraded account</div>
                <div className="text-center">

                  <FaArrowRightLong fontSize={25} className="my-1"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
         <label htmlFor="asking" className="my-3">Do you want to save?</label>
         <div className="d-flex align-bottom justify-content-center w-100 align-item-center">
          <div className="btn themeButton px-4 mx-1" onClick={handleClose}>continue</div>
          <div className=" btn whiteThemeButton px-4 mx-1" onClick={()=>{ setFormData({})
          navigate(path[0])
          handleClick(path[1])
          toggleSubmenu(path[2]?path[1]:null)
          handleClose()}}>Close</div>
        </div>
        </Box>
      </Modal>
      </>
    );
  };

  export default Sidebar;
