import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import "./Layout.css"
import { CSSTransition, TransitionGroup } from "react-transition-group";


const Layout = ({ children }) => {
  const location = useLocation();

  const [active, setActive] = useState("Dashboard")

  // const location= useLocation()
  // useEffect(()=>{
  //   if(location.pathname==="/"){
  //     setActive("dashboard")
  //   }
  // })
  // console.log(active)

  const handleClick = (item) => {
    setActive(item);
    localStorage.setItem("activeItem", item)
  };
  // const location=useLocation()
// console.log(location,"activeeeeee");

  return (
    <>
      <div className="d-flex">
        <Sidebar handleClick={handleClick} active={active} setActive={setActive} />
        <div className="content">
          <Navbar handleClick={handleClick} active={active} />
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={300} 
            >

              <div className="layoutContainer" key={active}>

                {children}
                <Outlet />
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </>
  );
};

export default Layout;
