import { createContext, useState } from "react";
import React from 'react'

export const context=createContext()

export const FormContext = ({children}) => {  
    const [formData, setFormData] = useState({});
    
  return (
    <context.Provider value={{formData,setFormData}}>
        {children}
    </context.Provider>
  )
}

