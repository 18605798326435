import logo from './logo.svg';
import './App.css';
import Layout from "./Components/Layout/Layout"
import Insurance from './Components/Insurance/Insurance';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loans from './Components/Loans/Loans';
import UploadExcelFile from './Components/UploadExcelFile/UploadExcelFile';
import Dashboard from './Components/Dashboard/Dashboard';
import AddAgent from './Components/TeamManagement/AddAgent';
import Switch from './Components/Switch/Switch';
import UploadExcelForm from './Components/UploadExcelFile/UploadExcelForm';
import DataView from "./Components/DataView/DataView"
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ViewAgent from './Components/TeamManagement/ViewAgent';

function App() {
  
  return (
    
    <Routes>
       
    <Route path='/' element={<Layout />} >
    

      <Route path='/' index element={<Dashboard/>}/>
      <Route path='uploadExcel'element={<UploadExcelFile/>} />
      <Route path='uploadExcelForm'element={<UploadExcelForm/>} />
      <Route path='reportsDataView'element={<DataView/>} />
      <Route path='loans' element={<Loans />} />
      <Route path='addAgent' element={<AddAgent />} />
      <Route path='viewAgent' element={<ViewAgent />} />

      {/* <Route path='insurance' element={<Insurance />} /> */}
      <Route path='carInsurance' element={<Insurance />} />
      <Route path='bikeInsurance' element={<Insurance />} />
      <Route path='healthInsurance' element={<Insurance />} />
      <Route path='lifeInsurance' element={<Insurance />} />

     
       

    </Route>
    
  </Routes>
  );
}

export default App;
