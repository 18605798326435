import React, { useEffect, useState } from 'react'
import "./Switch.css"
import { GoSun } from "react-icons/go";
import { GoMoon } from "react-icons/go";

const Switch = ({isDarkMode,setIsDarkMode}) => {

  const lightColor={
    color1:"#fff",
    color2:"rgba(68, 100, 54, 1)",
    color3:"rgba(0, 0, 0, 1)",
    color4:"rgba(157, 157, 157, 1)",
    color5:"rgba(255, 15, 0, 1)",
    color6:"rgba(157, 157, 157, 1)",
    color7:"rgba(68, 100, 54, 1)",


  }
  const darkColor={
    color1:"rgba(34, 40, 49, 1)",
    color2:"rgba(78, 159, 61, 1)",
    color3:"#fff",
    color4:"rgba(157, 157, 157, 1)",
    color5:"rgba(255, 15, 0, 1)",
    color6:"rgba(216, 233, 168, 1)",
    color7:"#fff",
  }

  
  useEffect(()=>{  
    if(isDarkMode){   
      document.documentElement.style.setProperty('--color1', darkColor.color1);
      document.documentElement.style.setProperty('--color3', darkColor.color3);
      document.documentElement.style.setProperty('--color2', darkColor.color2);
      document.documentElement.style.setProperty('--color4', darkColor.color4);
      document.documentElement.style.setProperty('--color6', darkColor.color6);
      document.documentElement.style.setProperty('--color7', darkColor.color7); 
    }
    else {  

      document.documentElement.style.setProperty('--color1', lightColor.color1);
      document.documentElement.style.setProperty('--color3', lightColor.color3);
      document.documentElement.style.setProperty('--color2', lightColor.color2);
      document.documentElement.style.setProperty('--color4', lightColor.color4);
      document.documentElement.style.setProperty('--color6', lightColor.color6);
      document.documentElement.style.setProperty('--color7', lightColor.color7);

      
    }
  },[isDarkMode])
  const changeDarkMode=()=>{
    if(isDarkMode){
      setIsDarkMode(!isDarkMode);
      localStorage.setItem("isDarkMode",false)
    }
    else{
      setIsDarkMode(!isDarkMode);
      localStorage.setItem("isDarkMode",true)
    }
  }
    
  return (
    <label className='switch flex-shrink-0 mx-1'>
        <input type='checkbox' checked={isDarkMode} onChange={changeDarkMode}/>
        <span className='slider'>
        <span className='icon-left'><GoSun/></span>
        <span className='icon-right'><GoMoon/></span> 
            </span>
    </label>
  )
}

export default Switch
