
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "../Switch/Switch"


import { MdUploadFile } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";

const Navbar = ({active}) => {
const navigate=useNavigate()
const [isDarkMode,setIsDarkMode]=useState(localStorage.getItem('isDarkMode')==="false"?false:true)
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

 
  return (
    <div>
    <nav className="navbar myNavbar d-flex justify-content-between border-bottom align-items-center px-4">

      <label
        className="flex-shrink-1 fw-bold"
        style={{ fontSize: "1.2rem" }}
      >
        {active}
      </label>
      <div className="position-relative d-xl-block d-none flex-grow-1  text-secondary" style={{marginInline:"100px"}}>
        <div className="position-absolute start-0 top-50 translate-middle-y  mt-0 ms-2"><IoIosSearch fontSize={25}/></div>
        <input 
        className="form-control w-100 flex-grow-1 ps-5"
        placeholder="Search"
        style={{borderRadius:".3cm"}}/>
      </div>
      <div className="d-flex justify-content-center align-items-center ">
      
      <Switch isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
     
      <div  className="mx-1 icon-background" onClick={()=>navigate("uploadExcel")}>
      <MdUploadFile/>
      </div>
      <div className="mx-1 icon-background">
        <MdOutlineSettings/>
      </div>
      <div  style={{width:"2px",height:"35px",background:"grey"}} className="mx-2"></div>
      <div className="d-flex justify-content-center align-items-center ">
        <div className="mx-1 icon-background">
          
          <AiOutlineUser/>
        </div>
        <div className="mx-1">
          <label className="d-block">Dennis Rusel</label>
          <label>Admin</label>
        </div>
      </div>
      </div>
     
  
     
    </nav>
  
    {/* {showModal && (
      <div
        className="modal fade show"
        style={{ display: "block", backdropFilter: "blur(5px)" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div>
              <img
                src="https://cdn0.iconfinder.com/data/icons/tiny-commerce/28/Close-1024.png"
                alt=""
                className="img-fluid float-right"
                style={{ height: 40, width: 40, margin: "10px" }}
                onClick={toggleModal}
              />
            </div>
  
            <div
              className="modal-body text-align-center d-flex flex-column justify-content-between"
              style={{ textAlign: "center", height: "80vh" }}
            >
              <div style={{ marginTop: "8rem" }}>
                <ul
                  className="list-unstyled"
                  style={{ padding: 0, margin: "20px 0" }}
                >
                  <li style={{ marginBottom: "10px" }}>
                    <Link className="text-dark" to="/" onClick={closeModal}>
                      Dashboard
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link className="text-dark" to="/reservations" onClick={closeModal}>
                      Reservation
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link className="text-dark" to="/table" onClick={closeModal}>
                      Table
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link className="text-dark" to="/order" onClick={closeModal}>
                      Order

                      </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link className="text-dark" to="/inventory" onClick={closeModal}>
                      Inventory
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link className="text-dark" to="/customer" onClick={closeModal}>
                      Customer
                    </Link>
                  </li>
                  <li>
                    <Link className="text-dark" to="/payment" onClick={closeModal}>
                      Payment
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <img
                  src="https://pngfre.com/wp-content/uploads/Black-Circle-1.png"
                  alt="logo"
                  className="ms-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <Link className="text-dark navbar-brand fw-500" to="/">
                Lazeez Affairs

                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )} */}
  </div>
  
  );
};

export default Navbar;
