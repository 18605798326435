import React, { useContext, useEffect, useState } from 'react'
import Data from "../Data.json"
import CrossIcon from "../../Assets/Images/Cancel.svg"
import { useNavigate } from 'react-router-dom'
import { RxCross2 } from "react-icons/rx";
import { NumericInput, AlphaNumericInput, AlphaInput } from "../Switch/Validation"
import { context } from '../Context/Context';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const Loans = () => {
  const navigate = useNavigate()
  const { formData, setFormData } = useContext(context);
  const fieldSet = (Data.loanAndCreditCard)
  const [products, setproducts] = useState([]);
  const [subproducts, setsubproducts] = useState([]);
  const isDarkMode = localStorage.getItem("isDarkMode") === "false" ? false : localStorage.getItem("isDarkMode")
  const [productId, setproductId] = useState('');
  const [subproductId, setsubproductId] = useState('');
  const [subproFullData, setsubFullData] = useState([])


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("")



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    // setFormData(prevData => {
    //   if (name === 'product') {
    //     return {
    //       ...prevData,
    //       [name]: productId
    //     };
    //   }
    //   if (name == 'subProduct') {
    //     return {
    //       ...prevData,
    //       [name]: subproductId
    //     };
    //   }
    //   return {
    //     ...prevData,
    //     [name]: value
    //   };
    // });
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  useEffect(() => {

    const fetchData = async () => {
      let tblname = 'product'
      try {
        const response = await fetch(`https://policydekhoo-cms-backend.onrender.com/ProductInfo?tblname=${tblname}`);

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        if (result.msg !== "No data found") {


          setproducts(result.element);
          console.log(result, "kkjfnn");
        }


      } catch (err) {
        console.log(err);

      }
    };
    fetchData();
  }, [])

  useEffect(() => {

    const fetchData = async () => {
      let tblname = 'subproduct'
      try {
        const response = await fetch(`https://policydekhoo-cms-backend.onrender.com/ProductInfo?tblname=${tblname}`);


        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        if (result.msg !== "No data found") {

          console.log(formData.product, "ll");
          setsubFullData(result.element)
          if (formData.product) {


            let arr = [];
            products.forEach((element) => {
              if (element.product_name === formData.product) {
                setproductId(element.product_id);
                let id = element.product_id
                result.element.forEach((element) => {
                  if (element.table_product_product_id == id) {
                    arr.push(element.subproduct_name)
                  }
                })



              }
            })
            setsubproducts(arr)
            if (formData.subProduct) {
              subproFullData.forEach((ele) => {
                if (ele.subproduct_name === formData.subProduct) {
                  setsubproductId(ele.subproduct_id)
                }
              })

            }

          }
        }


      } catch (err) {
        console.log(err);

      }
    };
    fetchData();
  }, [formData.product, formData.subProduct])




  const handleSubmit = async (e) => {
    e.preventDefault()
    formData.product = productId;
    formData.subProduct = subproductId
    let tblename = 'loan'
    try {
      console.log("+insdide try");
      const response = await fetch(
          `https://policydekhoo-cms-backend.onrender.com/manualEntry?tblename=${tblename}`,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
          }
      );
      if (response.ok) {

        console.log("Data added successfully");
        setSnackbarMessage("Data added successfully");
        setSnackbarOpen(true)
        setFormData({})


      } else {
        console.error("Failed to add data");
        setSnackbarMessage("Failed to add data");
        setSnackbarOpen(true)

      }
    } catch (error) {
      console.error("Error:", error);
    }


  }









  return (
    <div className='container-fluid text-color-dark'>
      <div className="row">
        <div className="col-1"></div>
        <div className="col text-center"><h5>Loans</h5></div>
        <div className="col-1 text-end">
          <RxCross2 fontSize={22} onClick={() => navigate("/", { state: { activeName: "Dashboard" } })} />
        </div>
      </div>
      {Object.keys(fieldSet).map((dataSet, index) => (
        <>
          <div className="d-flex align-items-center my-2">
            <div className="me-3" style={{ whiteSpace: "nowrap" }}><label className='fw-bold text-muted'>{dataSet}</label></div>
            <div className='w-100'><hr /></div>
          </div>
          <div className="row align-items-center px-5">
            {fieldSet[dataSet].map((fields) => (
              <>
                <div className="col-lg-3 col-sm-6 my-2"><label>{fields.label}</label></div>
                <div className={`col-lg-3 col-sm-6 my-2 ${fields.type === "dropdown" ? "select_box" : ""}`}>
                  {
                    fields.type === "dropdown" ? <>
                      <select class="form-select w-100" name={fields.name} onChange={handleChange} style={{ color: formData[fields.name] === undefined || formData[fields.name] === "" ? "rgba(157, 157, 157, 1)" : isDarkMode ? "#fff" : "rgba(0, 0, 0, 1)" }}>
                        <option value="" >Select {fields.label}</option>
                        {
                          fields.label === 'Product' ? (
                            <>
                              {products.map((option) => (
                                <option value={option.product_name} key={option.product_name}>
                                  {option.product_name}
                                </option>
                              ))}
                            </>
                          ) : fields.label === 'Sub Product' ? (
                            <>
                              {subproducts.map((option, index) => (
                                <option value={option} key={index}>
                                  {option}
                                </option>
                              ))}
                            </>
                          ) : (
                            fields.dropdownOption.map((option) => (
                              <option value={option} key={option}>
                                {option}
                              </option>
                            ))
                          )
                        }

                      </select></> :
                      fields.type === "date" ? <>
                        <>
                          <input type={"date"}
                          className={`w-100 form-control ${formData[fields.name]!==undefined&&formData[fields.name]!==""?"date-input--has-value":""}`}
                            name={fields.name}
                            value={formData[fields.name] || ''}
                            onChange={handleChange}
                            placeholder={`Enter ${fields.label}`} /></>

                      </> :

                        <>
                          <input className='w-100 form-control'
                            onKeyPress={fields?.validation[0] === "Alpha" ? AlphaInput : fields?.validation[0] === "Numeric" ? NumericInput : AlphaNumericInput}
                            maxLength={fields?.validation[1] === "" ? 100 : parseInt(fields?.validation[1])}
                            name={fields.name}
                            value={formData[fields.name] || ''}
                            onChange={handleChange}
                            placeholder={`Enter ${fields.label}`} /></>
                  }



                </div>

              </>
            ))}
          </div>
        </>
      ))}
      <div className="text-center my-5">
        <button className='button px-5 py-2 ' onClick={handleSubmit}>Save</button>
      </div>
      <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
    </div>
  )
}

export default Loans