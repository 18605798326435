import React, { useContext, useEffect, useState } from 'react'
import Data from "../Data.json"
import CrossIcon from "../../Assets/Images/Cancel.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import { RxCross2 } from 'react-icons/rx'
import { context } from '../Context/Context';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const UploadExcelForm = () => {
    const {formData,setFormData}=useContext(context);
    const fieldSet = (Data.excelUploadData)
    const navigate = useNavigate()
    const data = useLocation()
    const data1 = data.state
    const isDarkMode = localStorage.getItem("isDarkMode")==="false"?false:localStorage.getItem("isDarkMode")
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("")
  
  
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSnackbarOpen(false);
    };
  
    const action = (
      <React.Fragment>
  
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    
  

    const handleChange = (e) => {
        const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        let formData1=[]
        Object.keys(formData).map((keyss)=>{
            formData1.push({[keyss]:formData[keyss]})
        })
        console.log(formData1)
        let newarr=formData1;
        let val=newarr.find(type => type.EntryType ==="EntryType" )
        if(!val)
        {

            newarr.push({EntryType:"EntryType"})
        }
        const data = {
            formData: formData1,
            excelData: data1.jsonData
        }
        console.log(data, "jjj");

        try {
            console.log("+insdide try");
            const response = await fetch(
                "https://policydekhoo-cms-backend.onrender.com/BulkUploader",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );
            if (response.ok) {

                console.log("Data added successfully");
                setSnackbarMessage("Data added successfully");
                setSnackbarOpen(true)
                setFormData({})
                


            } else {
                console.error("Failed to add data");
                setSnackbarMessage("Failed to add data");
                setSnackbarOpen(true)
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }


    const states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ];
    const source = ["online", "self", "other"]
    const type = ["Motor", "Health", "Life", "Other"]
    const category = ["GCV", "HCV", "Pvt", "2W", " Miss", "4W"]
    return (
        <div className='container-fluid text-color-dark'>
            <div className="row">
                <div className="col-1"></div>
                <div className="col text-center"><h5>Data Select</h5></div>
                <div className="col-1 text-end">
                <RxCross2 fontSize={22} onClick={() => navigate("/", { state: { activeName: "Dashboard" } })} style={{cursor:"pointer"}} />
                </div>
            </div>

            {Object.keys(fieldSet).map((dataSet, index) => (
                <>
                    <div className="d-flex align-items-center my-2">
                        <div className="me-3" style={{ whiteSpace: "nowrap" }}><label className='fw-bold text-muted'>{dataSet}</label></div>
                        <div className='w-100'><hr /></div>
                    </div>
                    <div className="row align-items-center px-4">
                        {fieldSet[dataSet].map((fields) => (
                            <>
                                <div className="col-lg-3 col-sm-6 my-1"><label>{fields.label}</label></div>
                                <div className="col-lg-3 col-sm-6 my-1 select_box">
                                    {
                                        fields.type === "dropdown" ? <>
                                            <select class="form-select w-100" name={fields.name} onChange={handleChange} 
                                            style={{color:formData[fields.name]===undefined||formData[fields.name]===""?"rgba(157, 157, 157, 1)":isDarkMode?"#fff":"rgba(0, 0, 0, 1)"}}
                                            >
                                                <option value="" selected>Select {fields.label}</option>
                                                {fields.name === "DealerState" ? <>
                                                    {states.map((option) => (
                                                        <option value={option}>{option}</option>
                                                    ))}
                                                </> :
                                                    fields.name === "DataSource" ? <>
                                                        {source.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </> : fields.name === "SelectVehicleCategory" ? <>
                                                        {category.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </> : <>
                                                        {data1?.headers.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </>}

                                            </select></> : <><input className='w-100 form-control' /></>
                                    }



                                </div>

                            </>
                        ))}
                    </div>
                </>
            ))}
            <div className="text-center my-5">
                <button className='button px-5 py-2 ' onClick={handleSubmit}>Save</button>
            </div>
            <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
        </div>
    )
}

export default UploadExcelForm