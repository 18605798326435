// import { upload } from '@testing-library/user-event/dist/upload';
import React, { useEffect, useRef, useState } from 'react';
import CrossIcon from "../../Assets/Images/Cancel.svg"
import { useNavigate } from 'react-router-dom';
import * as XLSX from "xlsx";
import { RxCross2 } from "react-icons/rx";

const UploadExcelFile = () => {
  const navigate=useNavigate();
  const [fileName, setFileName] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    console.log("inij");
    
    const files = event.target.files[0];
    console.log(files,"kkj");
    setFileName(files)
    
    // handleFiles(files);
  };

  // const handleFiles = (files) => {
  //   if (files.length > 0) {
  //     setFileName(files);
  //     // Here you would typically handle the file upload
  //     console.log('File selected:', files[0]);
  //   }
  //   setFileName(files)
  // };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    
    const files = event.dataTransfer.files[0];
    console.log(files,"kfjjk");
    
    setFileName(files)
    // handleFiles(files);
  };

  
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  useEffect(()=>{
    if (fileName) {
        console.log(fileName,'cckkk');
        const fileReader = new FileReader();
         fileReader.readAsBinaryString(fileName);
      
            fileReader.onload = (event) => {
                // console.log("dgfhgsdfhghvghvf");
           const fileData = event.target.result;
          //  console.log(fileData,"kdkkj");
           
              const workbook = XLSX.read(fileData, { type: "binary" });
              const newData = [];
    
              const worksheet = workbook.Sheets[workbook.SheetNames[0]]
              const jsonData=XLSX.utils.sheet_to_json(worksheet)
              const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];                
              console.log(jsonData,headers);

              navigate("/uploadExcelForm",{state:{jsonData:jsonData,headers:headers}})
             
            //   ExcelContxt.setData(jsonData)
            //   ExcelContxt.setHeaders(headers)
            // setoptions(headers)
        }
      }
},[fileName])
  return (
    <div className="container-fluid text-color-dark">
        <div className="d-flex justify-content-between">
            <label className='fw-bold'>Excel Upload</label>
            <RxCross2 fontSize={22} onClick={() => navigate("/", { state: { activeName: "Dashboard" } })} style={{cursor:"pointer"}} />
        </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card myCard border-2 border-secondary"
            style={{ 
                borderStyle: 'dashed', 
                transition: 'all 0.2s ease-in-out',
                transform: isDragging ? 'scale(1.02)' : 'scale(1)'
              }}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
          
          
          >
            <div className="card-body text-center py-5">
              {/* <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" className="text-success mb-3" /> */}
              <h5 className="mb-3">Drag & Drop files here</h5>
              <p className="text-muted mb-3">or</p>
              <div className="mb-3">
                <label htmlFor="fileInput" className="btn themeButton" onClick={handleBrowseClick}>
                  Browse
                </label>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="d-none"
                  onChange={handleFileChange}
                  accept=".xlsx"
                />
              </div>
              {fileName===""?<p className="text-muted">Drag a File here</p>: <p className="text-muted">Selected file: {fileName?.name}</p>}
              <p className="text-danger mb-0">* File Supported - .xlsx</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadExcelFile;