import React, { useContext, useRef, useState } from 'react'
import Data from "../Data.json"
import { useLocation, useNavigate } from 'react-router-dom';
import CrossIcon from "../../Assets/Images/Cancel.svg"
import Upload from "../../Assets/Images/Upload.svg"
import { RxCross2 } from "react-icons/rx";
import { NumericInput, AlphaNumericInput, AlphaInput } from "../Switch/Validation"
import { MdOutlineFileUpload } from "react-icons/md";
import { context } from '../Context/Context';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Insurance = () => {
  const {formData,setFormData}=useContext(context);
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null)
  console.log(location,"[][][][][]")
  const InsuranceType = (location.pathname) === "/carInsurance" || location.pathname === "/bikeInsurance" ? "carAndBikeInsurance" : (location.pathname)==="/lifeInsurance"? "lifeInsurance":"healthInsurance"
  const fieldSet = (Data[InsuranceType])
  // console.log(fieldSet, "======")
  const [vehicleInfo, setVehicleInfo] = useState([])
  const isDarkMode = localStorage.getItem("isDarkMode")==="false"?false:localStorage.getItem("isDarkMode")

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage,setSnackbarMessage]=useState("")

  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
     
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  const handleFileChange = (e) => {
    if (inputRef.current.files.length > 0) {
      const file = inputRef.current.files[0]
      console.log(file, e.target.name)
      const { name } = e.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: file.name
      }));
    }
  }

  const handleChassisBlur = () => {
    console.log("inside handle bluekjj");
    if (formData["VehicleNo"] && formData["ChasisNo"]) {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://policydekhoo-cms-backend.onrender.com/vehicleInfo?vehicleNo=${formData["VehicleNo"]}&ChasisNo=${formData["ChasisNo"]}`);

          // Check if the response is OK (status code 200-299)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          if (result.msg !== "No data found") {


            setVehicleInfo(result);
            console.log(result, "kkjfnn");
          }


        } catch (err) {
          console.log(err);

        }
      };
      fetchData();

    }

  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  // console.log(formData, "kkk");
  const handleSubmit = async () => {
    let tblename;
    if (InsuranceType == 'carAndBikeInsurance') {
      tblename = 'vehicleinsurance'
    }
    if (InsuranceType == 'healthInsurance') {
      tblename = 'healthinsurance'
    }
    if (InsuranceType == 'lifeInsurance') {
      tblename = 'lifeinsurance'
    }
    console.log(tblename, "kfkkfmmmm");

    try {
      console.log("+insdide try");
      const response = await fetch(
        ` https://policydekhoo-cms-backend.onrender.com/manualEntry?tblename=${tblename}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {

        console.log("Data added successfully");
        setSnackbarMessage("Data added successfully");
        setSnackbarOpen(true)
        setFormData({})


      } else {
        console.error("Failed to add data");
        setSnackbarMessage("Failed to add data");
        setSnackbarOpen(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }

  }

console.log(formData);


  return (
    <div className='container-fluid text-color-dark' key={location.pathname}>
      <div className="row">
        <div className="col-1"></div>
        <div className="col text-center"><h5>{(location.pathname) === "/carInsurance" ? "Car Insurance": location.pathname === "/bikeInsurance" ? "Bike Insurance" : (location.pathname)==="/lifeInsurance"? "Life Insurance":"Health Insurance"}</h5></div>
        <div className="col-1 text-end">
          <RxCross2 fontSize={22} onClick={() => navigate("/", { state: { activeName: "Dashboard" } })} />
        </div>
      </div>
      {Object.keys(fieldSet).map((dataSet, index) => (
        <>
          <div className="d-flex align-items-center my-2">
            <div className="me-3" style={{ whiteSpace: "nowrap" }}><label className='fw-bold text-muted'>{dataSet}</label></div>
            <div className='w-100'><hr /></div>
          </div>
          <div className="row align-items-center px-5">
            {fieldSet[dataSet].map((fields) => (
              <>
                <div className="col-lg-3 col-sm-6 my-2"><label>{fields.label}</label></div>
                <div className={`col-lg-3 col-sm-6 my-2 ${fields.type === "dropdown"?"select_box":""}`}>
                  {
                    fields.type === "dropdown" ? <>
                      <select class="form-select w-100" name={fields.name} onChange={handleChange} style={{color:formData[fields.name]===undefined||formData[fields.name]===""?"rgba(157, 157, 157, 1)":isDarkMode?"#fff":"rgba(0, 0, 0, 1)"}}>
                        <option value="" selected >Select {fields.label}</option>
                        {fields.dropdownOption.map((option) => (
                          <option value={option} >{option}</option>
                        ))}

                      </select></> : fields.type === "upload" ? <>
                        <div className="position-relative">
                          <input
                            type="text"
                            name={fields.name}
                            value={formData[fields.name] || ""}
                            placeholder="Upload"
                            onClick={() => inputRef.current.click()}
                            className="form-control w-100"
                          />

                          <MdOutlineFileUpload
                            style={{ cursor: 'pointer' }}
                            fontSize={30}
                            onClick={() => inputRef.current.click()}
                            className="position-absolute top-50 end-0 translate-middle-y me-2 uploadButton"
                          />
                        </div>

                        <input
                          type="file"
                          accept="image/*"
                          name={fields.name}
                          ref={inputRef}
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                        />
                      </>
                      :
                      fields.type === "date" ? <>
                        <>
                          <input type={"date"}
                            className={`w-100 form-control ${formData[fields.name]!==undefined&&formData[fields.name]!==""?"date-input--has-value":""}`}
                            name={fields.name}
                            onBlur={fields.name === 'ChasisNo' ? handleChassisBlur : undefined}
                            value={formData[fields.name] || ''}
                            onChange={handleChange}
                            placeholder={`Enter ${fields.label}`}
                             /></>

                      </> :
                        <>
                          <input 
                            onKeyPress={ fields?.validation[0] === "Alpha" ? AlphaInput :  fields?.validation[0] === "Numeric" ? NumericInput : AlphaNumericInput}
                            maxLength={fields?.validation[1]===""?100:parseInt(fields?.validation[1])}
                            className='w-100 form-control'
                            name={fields.name}
                            onBlur={fields.name === 'ChasisNo' ? handleChassisBlur : undefined}
                            value={formData[fields.name] || ''}
                            onChange={handleChange}
                            placeholder={`Enter ${fields.label}`} /></>
                  }



                </div>

              </>
            ))}
          </div>
        </>
      ))}
      <div className="text-center my-5">
        <button className='button px-5 py-2 ' onClick={handleSubmit}>Save</button>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
    </div>
  )
}

export default Insurance