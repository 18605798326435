import React from 'react'

const Dashboard = () => {
    const date = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const carddata = {
        "Total Application": 863,
        "Pending Application": 120,
        "Recalling Users": 120,
        "Total Caller Active": 11
    }
    return (
        <div>
            <div className="text-start text-color-dark">
                <label className='d-block fw-bold'>Hello {"Dennis"}</label>
                <label className='d-block'>{formattedDate}</label>
            </div>
            <div className="row my-2 align-items-stretch">
                {Object.keys(carddata).map((itemName, index) => (
                    <div className="col-lg-3 col-6 py-1 px-2 d-flex justify-content-center  align-items-stretch">
                        <div className="card myCard w-100">
                            <div className="card-body">
                                <label  className={`d-block ${itemName==="Pending Application"?"text-danger":"text-color-light"}`}>
                                    {itemName}
                                </label>
                                <label className="d-block fs-4">
                                    {carddata[itemName]}
                                </label>
                            </div>
                        </div>
                    </div>

                ))}
            </div>

            <div className="row my-2">
                <div className="col-lg-7 col-12">
                    <div className="card myCard my-1" style={{height:"200px"}}>
                        <div className="card-body">

                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="card myCard w-100 my-1 me-1" style={{height:"100px"}}>
                            <div className="card-body"></div>
                        </div>
                        <div className="card myCard w-100 my-1 ms-1" style={{height:"100px"}}>
                            <div className="card-body"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-12 text-color-dark">
                    <label className='fw-bold'>Internal Teams</label>
                    <hr className='my-2'/>
                    <div className="card myCard my-2" style={{height:"140px"}}>
                        <div className="card-body"></div>
                    </div>
                    <div className="card  myCard my-2" style={{height:"140px"}}>
                        <div className="card-body"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
